import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { CustomHtmlPrompt } from '../../../components/inputs';

const AdditionalInfo = ({ additionalInfo, moreText = 'More' }) => {
  const reactTooltipRef = useRef(null);
  const reactTooltipContentRef = useRef(null);
  const reactTooltipButtonRef = useRef(null);
  return (
    <React.Fragment>
      {' '}
      <button
        data-tip
        data-event="click"
        data-for="react-tooltip-addi-info"
        className="info-btn"
        type="button"
        ref={reactTooltipButtonRef}
      >
        {moreText}
      </button>
      <ReactTooltip
        type="light"
        effect="solid"
        place="bottom"
        border
        globalEventOff="click"
        clickable
        id="react-tooltip-addi-info"
        afterShow={() => {
          reactTooltipContentRef.current?.focus();
        }}
        afterHide={() => {
          reactTooltipButtonRef.current?.focus();
        }}
        className="additional-info-box"
        ref={reactTooltipRef}
        overridePosition={(
          { left, top },
          currentEvent,
          currentTarget,
          node,
          place,
          desiredPlace,
          effect,
          offset,
        ) => {
          const rect = node.getBoundingClientRect();
          const screenWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
          const screenHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          if (top < 10) {
            top = 10;
          } else if (top + rect.height > screenHeight) {
            top = screenHeight - rect.height - 10;
          }

          if (left < 10) {
            left = 10;
          } else if (left + rect.width > screenWidth) {
            left = screenWidth - rect.width - 10;
          }

          return { left, top };
        }}
      >
        <div
          role="dialog"
          aria-modal="true"
          tabIndex={-1}
          ref={reactTooltipContentRef}
        >
          <button
            type="button"
            className="close-tooltip fa fa-close"
            aria-label="close popup"
            onClick={() => {
              reactTooltipRef.current.tooltipRef = null;
              ReactTooltip.hide();
            }}
          />
          <div
            className="addi-info-content"
            id="react-tooltip-addi-info-content"
          >
            <CustomHtmlPrompt label={additionalInfo} />
          </div>
        </div>
      </ReactTooltip>
    </React.Fragment>
  );
};
export default AdditionalInfo;
