import React from 'react';
import axios from 'axios';
import { useField } from 'formik';
import wrapField from './wrapField';
import { useAuthentication } from '../../../context/authentication';
import FieldError from './FieldError';
import Prompt from './Prompt';

const MultiProxy = (props) => {
  const {
    field,
    meta: { error, touched, value },
    helpers,
    label,
    options,
    disabled,
    stringValues,
    organizationRelationships,
    values,
    handleChange,
    submitForm,
    track_id,
    survey_id,
    mode,
    additionalInfo,
    moreText,
    ariaLabelledby,
  } = props;

  const createNewProxyField = useField('createNewProxy');
  const createNewProxy = createNewProxyField[0].value;
  const changeCreateNewProxy = createNewProxyField[2].setValue;
  const { token } = useAuthentication();

  const handleAddNew = () => {
    changeCreateNewProxy(true);
  };
  const handleBack = () => {
    changeCreateNewProxy(false);
  };
  const handleCreate = () =>
    axios({
      method: 'POST',
      url: `/api/v1/tracks/${track_id}/proxies`,
      data: {
        first_name: values.first_name,
        last_name: values.last_name,
        relationship_id: values.relationship_id,
        survey_id,
        survey_token: token,
      },
    })
      .then((r) => {
        if (r.data && r.data.value) {
          helpers.setValue(r.data.value);
          submitForm();
        } else if (r.data && r.data.message) {
          alert(r.data.message);
        } else {
          alert('Something went wrong. Please try again.');
        }
      })
      .catch((err) => {
        const msg =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      })
      .then(() => {
        // setLoader(false);
      });

  return (
    <fieldset className="checkbox-group" aria-labelledby={ariaLabelledby}>
      <Prompt {...{ label, additionalInfo, moreText }} />
      {!createNewProxy ? (
        <div
          className={
            options.length >= 7 ? 'options-group-flex' : 'options-group'
          }
        >
          {options.map((option, i) => (
            <div key={option.value} className="user-option-box option-tab">
              <input
                {...field}
                name={field.name}
                type="radio"
                value={option.value.toString()}
                // eslint-disable-next-line eqeqeq
                checked={option.value == value}
                disabled={disabled}
                id={`${field.name}_option_${i}`}
              />
              <label htmlFor={`${field.name}_option_${i}`}>
                {option.label}
              </label>
            </div>
          ))}

          <br />
          {options.length >= 5 ? (
            <p className="info">
              {stringValues['register-proxy-relationship-max-label']}
            </p>
          ) : (
            <button
              name="nav-button"
              id="add-new-btn"
              className="assessment_white_btn"
              type="button"
              onClick={handleAddNew}
            >
              {stringValues['register-proxy-relationship-add-label']}
            </button>
          )}
        </div>
      ) : (
        <div className="name-entry clear rows">
          <div className="name-entry-inner">
            <div className="name-column1 flt-lft name-field">
              <label htmlFor="first_name">
                <sup>*</sup>
                {stringValues['register-first-name-label']}
              </label>
              <input
                id="first_name"
                type="text"
                className="first_name input"
                name="first_name"
                autoComplete="off"
                maxLength="15"
                value={values.first_name}
                onChange={handleChange}
              />
            </div>
            <div className="name-column3 flt-lft name-field">
              <label htmlFor="last_name">
                <sup>*</sup>
                {stringValues['register-last-name-label']}
              </label>
              <input
                id="last_name"
                type="text"
                className="last_name input"
                name="last_name"
                autoComplete="off"
                maxLength="15"
                value={values.last_name}
                onChange={handleChange}
              />

              {/* <span className="error_lastname">
              {stringValues['valid_lastname']}
            </span> */}
            </div>
            <div
              className="name-column1 flt-lft name-field"
              style={{ marginTop: 25 }}
            >
              <label htmlFor="relationship">
                <sup>*</sup>
                {stringValues['register-proxy-relationship-label']}
              </label>

              <select
                id="relationship"
                className="chosen-select form-control input"
                name="relationship_id"
                value={values.relationship_id}
                onChange={handleChange}
                style={{ height: 58, marginTop: 13 }}
              >
                <option value="">{stringValues['select']}</option>
                {organizationRelationships &&
                  organizationRelationships.map((o) => (
                    <option key={o.id} value={o.id}>
                      {o.name}
                    </option>
                  ))}
              </select>
              {/* <span className="error_relationship">
              {stringValues['valid_relationship']}
            </span> */}
            </div>
          </div>

          <div className="navigation-buttons-space clear ">
            {values.first_name && values.last_name && values.relationship_id && (
              <button
                type="button"
                className="flt-rgt next-button nav-btn1 nav-btn-nxt next-btn-active "
                id="btn-next"
                name="nav-button"
                value="Next"
                tabIndex="0"
                onClick={handleCreate}
              >
                {(mode === 'edit'
                  ? stringValues['save-changes-button']
                  : stringValues['next-button']) || 'Next'}
              </button>
            )}
            <button
              type="button"
              className="btn-back flt-lft nav-btn1"
              name="nav-button"
              value="Back"
              id="btn-back"
              onClick={handleBack}
            >
              {(mode === 'edit'
                ? stringValues['cancel-changes-button']
                : stringValues['back-button']) || 'Back'}
            </button>
          </div>
        </div>
      )}

      <FieldError {...{ error, touched }} />
    </fieldset>
  );
};

export default wrapField(MultiProxy);
