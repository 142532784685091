import React from 'react';
// import sanitizeHtml from 'sanitize-html';

const CustomHtmlPrompt = (props) => {
  const { label, component } = props;
  const CustomTag = component || 'span';

  // To support stylised prompts with tags like strong, br etc
  return <CustomTag dangerouslySetInnerHTML={{ __html: label }} />;
};

export default CustomHtmlPrompt;
