import React from 'react';
import { FieldArray } from 'formik';
import wrapField from './wrapField';
import Prompt from './Prompt';
import FieldError from './FieldError';

const TextField = (props) => {
  const {
    field,
    meta: { error, touched, value = [] },
    label,
    options,
    disabled,
    additionalInfo,
    moreText,
    ariaLabelledby,
  } = props;

  return (
    <fieldset className="checkbox-group" aria-labelledby={ariaLabelledby}>
      <Prompt {...{ label, additionalInfo, moreText }} id="checkbox_label" />
      <FieldArray
        name={field.name}
        render={(arrayHelpers) => (
          <div aria-labelledby="checkbox_label">
            <div
              className={
                options.length >= 7 ? 'options-group-flex' : 'options-group'
              }
            >
              {options.map((option, i) => (
                <div key={option.value} className="user-option-box option-tab ">
                  <input
                    name={`${field.name}_option_${i}`}
                    id={`${field.name}_option_${i}`}
                    type="checkbox"
                    value={option.value.toString()}
                    checked={value.includes(option.value.toString())}
                    disabled={disabled}
                    onChange={(e) => {
                      if (e.target.checked) {
                        if (option.is_none_of_the_above) {
                          value.splice(0, value.length);
                        } else {
                          value.forEach((item, index) => {
                            if (
                              (options.find((o) => o.value == item) || {})
                                .is_none_of_the_above
                            ) {
                              value.splice(index, 1);
                            }
                          });
                        }
                        arrayHelpers.push(option.value.toString());
                      } else {
                        const idx = value.indexOf(option.value.toString());
                        arrayHelpers.remove(idx);
                      }
                    }}
                  />
                  <label htmlFor={`${field.name}_option_${i}`}>
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
      />
      <FieldError {...{ error, touched }} />
    </fieldset>
  );
};

export default wrapField(TextField);
