import React from 'react';
import CustomHtmlPrompt from './CustomHtmlPrompt';
import Prompt from './Prompt';

const Message = (props) => {
  const { label, additionalInfo, moreText = 'more', warningText } = props;
  return (
    <div>
      <Prompt {...{ label, additionalInfo, moreText }} component="h1" />
      {warningText && (
        <div className="prompt">
          <CustomHtmlPrompt label={warningText} />
        </div>
      )}
    </div>
  );
};

export default Message;
