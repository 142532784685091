import React, { useState } from 'react';
import wrapField from './wrapField';
import { masks } from '../../../helperFunctions';
import Prompt from './Prompt';
import FieldError from './FieldError';

const TextArea = (props) => {
  const {
    field,
    meta: { error, touched },
    helpers: { setValue },
    placeholder,
    label,
    autoComplete = 'off',
    areaLabel,
    mask,
    normalize,
    showRequiredLabel,
    additionalInfo,
    moreText,
    onChange,
    disabled,
    allowNoResponse,
    allowNoResponseLabel,
    allowNoResponseValue,
    innerRef,
    className = 'input',
    ariaLabelledby,
  } = props;
  const [userEnteredText, setUserEnteredText] = useState(false);
  return (
    <div>
      <fieldset className="field-wrape-text" aria-labelledby={ariaLabelledby}>
        <Prompt {...{ label, showRequiredLabel, additionalInfo, moreText }} />
        <div className="prompt">
          <textarea
            {...field}
            value={
              allowNoResponse &&
              !userEnteredText &&
              field.value == allowNoResponseValue
                ? ''
                : field.value || typeof field.value === 'number'
                ? field.value
                : ''
            }
            placeholder={placeholder}
            id={field.name}
            autoComplete={autoComplete}
            className={className}
            rows={4}
            aria-label={areaLabel}
            ref={innerRef}
            onChange={(e) => {
              setUserEnteredText(true);
              if (mask && masks[mask]) {
                setValue(masks[mask](e.target.value));
              } else if (normalize) {
                setValue(normalize(e.target.value));
              } else {
                field.onChange(e);
              }
              if (onChange) onChange(e.target.value);
            }}
            onBlur={(e) => {
              setValue(e.target.value.trim());
              field.onBlur(e);
            }}
            disabled={disabled}
          />

          {allowNoResponse && (
            <div
              style={{ marginTop: 50 }}
              className="user-option-box option-tab"
            >
              <input
                type="radio"
                id={`${field.name}_no_response`}
                name="phone-options"
                value={allowNoResponseValue}
                checked={
                  !userEnteredText && field.value == allowNoResponseValue
                }
                onChange={(e) => {
                  setUserEnteredText(false);
                  setValue(e.target.value);
                }}
              />
              <label htmlFor={`${field.name}_no_response`}>
                {allowNoResponseLabel}
              </label>
            </div>
          )}

          <FieldError {...{ error, touched }} />
        </div>
        {areaLabel && (
          <label
            htmlFor={field.name}
            className="text-box-warning asssessment-instruction"
            aria-hidden="true"
          >
            {areaLabel}
          </label>
        )}
      </fieldset>
    </div>
  );
};

export default wrapField(TextArea);
