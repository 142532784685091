import React from 'react';
import clsx from 'clsx';
import styles from './custom_messages.module.scss';
import ConfirmAppointment from './templates/ConfirmAppointment';
import ComponentLoader from '../UI/ComponentLoader';
import PlainMessage from './templates/PlainMessage';
import EmailMessage from './templates/EmailMessage';

const MessageBody = ({ metadata, message, isLoading, mode = 'view' }) => {
  const isLive = mode === 'live'; // mode options case be view, edit or live
  const { endpoint_tag, delivery_methods } = metadata || {};
  // TODO: This component will render different components based on tag, currently it only supports Confirm Appointment Message
  return (
    <ComponentLoader
      isLoading={isLoading}
      customClass="w-100"
      addMinHeight
      blurContentWhileLoading
    >
      <div
        className={clsx(
          isLive ? 'is-live' : 'is-preview',
          styles.previewBodyInner,
        )}
      >
        <div className={clsx(styles.previewContainer)}>
          {endpoint_tag === 'member_appointment_confirmation_email' ? (
            <ConfirmAppointment
              isLive={isLive}
              metadata={metadata}
              message={message}
            />
          ) : delivery_methods?.includes('email') ? (
            <EmailMessage message={message} metadeta={metadata} />
          ) : (
            <PlainMessage message={message} />
          )}
        </div>
      </div>
    </ComponentLoader>
  );
};

export default MessageBody;
